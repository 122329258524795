import HomeActionTypes from './home.types';
import { checkStatus } from './home.utils';
//import HOME_DATA from './home.data';

const INITIAL_STATE = {
    homeData: []
};

const HomeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HomeActionTypes.CHECK_STATUS:
            return {
                ...state,
                homeData: checkStatus(action.payload)
            }

        case HomeActionTypes.RELOAD_DATA:
            return {
                ...state,
                homeData: []
            }
    
        default:
            return state;
    }
};

export default HomeReducer;