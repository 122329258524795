import StatusActionTypes from './status.types';

const INITIAL_STATE = {
    change: false
};

const StatusReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case StatusActionTypes.TOGGLE_ACCORDION_CHANGE:
            return {
                ...state,
                change: !state.change
            }
    
        default:
            return state;
    }
};

export default StatusReducer;