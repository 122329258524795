import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ReactComponent as Logo } from '../../assets/images/etf-logo.svg';
import VPImage from '../../assets/images/vp.png';

import './header.styles.css';

const Header = () => (
    <Container className='header-section mb-4'>
        <Row>
            <Col xs='5' md='2'>
                <Link to='/' aria-label="Main Logo">
                    <Logo />
                </Link>
            </Col>
            <Col xs='3' md='2'>
                <img className='vp-image' src={ VPImage } alt='VP' />
            </Col>
        </Row>
    </Container>
);

export default Header;