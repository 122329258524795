import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import homeReducer from './home/home.reducer';
import stateReducer from './status/status.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['home', 'status']
};

const rootReducer = combineReducers({
    home: homeReducer,
    status: stateReducer
});

export default persistReducer(persistConfig, rootReducer);