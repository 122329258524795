import axios from 'axios';

const serverURL = "https://paxdatashare-public.eurotunnel3.com";
const axiosInstance = axios.create({
    baseURL: serverURL,
    //withCredentials: false,
    //credentials: 'same-origin',
    // Authentication: 'Basic ' + btoa(process.env.USERNAME + ":" + process.env.PASSWORD),
});

export default axiosInstance;